import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log } from 'utils';
import { fetchInformation, logout } from './auth';
import { ORGANIZATIONS_CLEAR_DATA } from './organizations';
import { SITES_CLEAR_DATA } from './sites';
import { errorMsg } from 'utils';
import { getCsrf } from 'components/Report/utils';

export const USERS_FETCH_DATA_INIT = createAction('USERS_FETCH_DATA_INIT');
export const USERS_FETCH_DATA_SUCCESS = createAction(
  'USERS_FETCH_DATA_SUCCESS'
);
export const USERS_FETCH_DATA_FAIL = createAction('USERS_FETCH_DATA_FAIL');

export const USERS_DELETE_USER_INIT = createAction('USERS_DELETE_USER_INIT');
export const USERS_DELETE_USER_SUCCESS = createAction(
  'USERS_DELETE_USER_SUCCESS'
);
export const USERS_DELETE_USER_FAIL = createAction('USERS_DELETE_USER_FAIL');

export const USERS_CLEAR_DATA = createAction('USERS_CLEAR_DATA');

export const USERS_FETCH_USER_DATA_INIT = createAction(
  'USERS_FETCH_USER_DATA_INIT'
);
export const USERS_FETCH_USER_DATA_FAIL = createAction(
  'USERS_FETCH_USER_DATA_FAIL'
);
export const USERS_FETCH_USER_DATA_SUCCESS = createAction(
  'USERS_FETCH_USER_DATA_SUCCESS'
);

export const USERS_CREATE_USER_INIT = createAction('USERS_CREATE_USER_INIT');
export const USERS_CREATE_USER_SUCCESS = createAction(
  'USERS_CREATE_USER_SUCCESS'
);

export const USERS_GET_USER_SUCCESS = createAction('USERS_GET_USER_SUCCESS');

export const USERS_CREATE_USER_FAIL = createAction('USERS_CREATE_USER_FAIL');

export const USERS_MODIFY_USER_INIT = createAction('USERS_MODIFY_USER_INIT');
export const USERS_MODIFY_USER_SUCCESS = createAction(
  'USERS_MODIFY_USER_SUCCESS'
);
export const USERS_MODIFY_PROFILE_SUCCESS = createAction(
  'PROFILE_MODIFY_USER_SUCCESS'
);
export const USERS_UNLOCK_USER_INIT = createAction('USERS_UNLOCK_USER_INIT');
export const USERS_UNLOCK_USER_SUCCESS = createAction(
  'USERS_UNLOCK_USER_SUCCESS'
);
export const USERS_UNLOCK_USER_FAIL = createAction('USERS_UNLOCK_USER_FAIL');
export const USERS_MODIFY_USER_FAIL = createAction('USERS_MODIFY_USER_FAIL');

export const USERS_CLEAN_UP = createAction('USERS_CLEAN_UP');

export const USERS_UPDATE_USER_DATA = createAction('AUTH_UPDATE_USER_DATA');

export const USERS_CLEAR_DATA_LOGOUT = createAction('USERS_CLEAR_DATA_LOGOUT');

export const fetchUsers = (userId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(USERS_FETCH_DATA_INIT());
    if (userId) {
      let userData;
      try {
        const res = await axios.post(
          `${url}/user/get`,
          {
            id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
        userData = res.data.data;
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(USERS_FETCH_DATA_FAIL({ error }));
      }

      const user = { ...userData, id: userId };
      return dispatch(
        USERS_FETCH_DATA_SUCCESS({
          list: getState().users.list,
          user: user,
        })
      );
    }

    const { uid } = localStorage.getItem('uid');

    let users;

    try {
      const res = await axios.get(`${url}/user/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      users = res.data.data;
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(USERS_FETCH_DATA_FAIL({ error }));
    }

    const usersData = users
      ? Object.entries(users).map(([key, value]) => ({
          id: key,
          ...value,
        }))
      : [];

    return dispatch(
      USERS_FETCH_DATA_SUCCESS({
        list: usersData.filter((user) => user.id !== uid),
        user: getState().users.user,
      })
    );
  };
};

export const deleteUser = (id) => {
  return async (dispatch, getState) => {
    dispatch(USERS_DELETE_USER_INIT());

    try {
      const csrfToken = await getCsrf();
      await axios.delete(`${url}/user/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        USERS_DELETE_USER_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The user was deleted.');
    return dispatch(USERS_DELETE_USER_SUCCESS({ id }));
  };
};

export const clearUsersData = () => {
  return (dispatch) => {
    dispatch(USERS_CLEAR_DATA());
  };
};

export const resetMFA = async (id) => {
  try {
    const csrfToken = await getCsrf();
    await axios.post(
      `${url}/user/resetMFA`,
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      }
    );
  } catch (error) {
    let errorMessage;
    if (error.response) {
      errorMessage = error && error.response && error.response.data.message;
    }
    toastr.error('', errorMessage);
  }
  toastr.success('', 'The user reset MFA is success');
};

export const clearUsersDataLogout = () => {
  return (dispatch) => {
    dispatch(USERS_CLEAR_DATA_LOGOUT());
    dispatch(SITES_CLEAR_DATA());
    dispatch(ORGANIZATIONS_CLEAR_DATA());
  };
};

export const createUser = (
  {
    name,
    email,
    password,
    organizationId,
    organizationName,
    file,
    role,
    roleId,
    createdAt,
    access,
    isAdmin,
    isScreen,
  },
  history
) => {
  return async (dispatch, getState) => {
    dispatch(USERS_CREATE_USER_INIT());
    // const { locale } = getState().preferences;
    let response;
    let formData = new FormData();

    // const accessData = {
    //   access: access,
    // };

    // log(accessData, '<<<< access data');

    formData.set('path', 'user');
    formData.set('name', name);
    formData.set('email', email);
    formData.set('organizationId', organizationId);
    formData.set('organizationName', organizationName);
    formData.set('isAdmin', isAdmin);
    formData.set('roleId', roleId);
    formData.append('file', file);
    formData.set('password', password);
    formData.set('isScreen', isScreen);

    const data = {
      name,
      email,
      organizationId,
      organizationName,
      role,
      isAdmin,
      roleId,
      password,
      access,
      isScreen,
    };

    try {
      const csrfToken = await getCsrf();
      response = await axios.post(`${url}/user/create`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          // 'content-type': 'multipart/form-data',
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
      log(response, '<<< create user response');
    } catch (error) {
      let errorMessage;
      if (error.response) {
        log(error.response, '<<<< ERROR RESPOINSE');
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'User created successfully');
    if (history) {
      if (isScreen) {
        history.push('/screen-users');
      } else {
        history.push('/users');
      }
    }
    return dispatch(USERS_CREATE_USER_SUCCESS({ user: response }));
  };
};

export const modifyProfile = ({ name, id }, history) => {
  return async (dispatch, getState) => {
    dispatch(USERS_MODIFY_USER_INIT());

    const data = {
      id,
      name,
    };

    let formData = new FormData();
    formData.set('path', 'user');
    formData.set('id', id);
    formData.set('name', name);

    try {
      const csrfToken = await getCsrf();
      await axios.post(`${url}/user/profile`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      let errorMessage;

      console.log(error.response, 'error');
      if (error.response) {
        errorMessage =
          error &&
          error.response &&
          error.response.data.errors &&
          error.response.data.errors.errors[0].msg;
      }
      toastr.error('', errorMessage);

      return dispatch(
        USERS_MODIFY_USER_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    if (id === uid) {
      dispatch(fetchInformation(id));
    }

    toastr.success('', 'Profile updated successfully');
    return dispatch(USERS_MODIFY_PROFILE_SUCCESS());
  };
};

export const modifyUser = (
  {
    name,
    organizationId,
    organizationName,
    isAdmin,
    role,
    access,
    file,
    createdAt,
    id,
    isEditing,
    roleId,
    isProfile,
    email,
    isScreen,
    fromScreenPage,
  },
  history
) => {
  return async (dispatch, getState) => {
    dispatch(USERS_MODIFY_USER_INIT());

    const data = {
      id,
      name,
      email,
      organizationId,
      organizationName,
      isAdmin,
      role,
      roleId,
      access,
      isScreen,
    };

    let response;
    let formData = new FormData();
    formData.set('path', 'user');
    formData.set('id', id);
    formData.set('name', name);
    formData.set('email', email);
    formData.set('roleId', roleId);
    formData.set('organizationId', organizationId);
    formData.set('organizationName', organizationName);
    formData.set('isAdmin', isAdmin);
    formData.set('role', role);
    formData.append('file', file);
    formData.set('isScreen', isScreen);

    const uid = localStorage.getItem('uid');

    try {
      const csrfToken = await getCsrf();
      response = await axios.post(`${url}/user/edit`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      let errorMessage;

      console.log(error.response, 'error');
      if (error.response) {
        errorMessage =
          error &&
          error.response &&
          error.response.data.errors &&
          error.response.data.errors.errors[0].msg;
      }
      toastr.error('', errorMessage);

      return dispatch(
        USERS_MODIFY_USER_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    if (isProfile) {
      toastr.success('', 'Profile updated successfully');
    } else if (isEditing) {
      toastr.success('', 'User updated successfully');
      if (history) {
        if (fromScreenPage) {
          history.push('/screen-users');
        } else {
          history.push('/users');
        }
      }
    }

    if (id === uid) {
      dispatch(fetchInformation(id));
    }

    return dispatch(
      USERS_MODIFY_USER_SUCCESS({
        user: response.data.data,
        id,
        message: response.data.message,
      })
    );
  };
};

export const modifyUserAccess = ({ id, user, accessObject }) => {
  return async (dispatch, getState) => {
    dispatch(USERS_MODIFY_USER_INIT());

    const access = JSON.parse(JSON.stringify(accessObject));

    const userData = {
      ...user,
      id,
      access,
    };

    try {
      const csrfToken = await getCsrf();
      await axios.post(`${url}/user/access`, userData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);

      return dispatch(
        USERS_MODIFY_USER_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'User access updated successfully');
    return dispatch(USERS_MODIFY_USER_SUCCESS({ user: userData, id }));
  };
};

export const checkUserData = () => {
  return (dispatch, getState) => {
    const { id } = getState().auth.userData;

    if (!id) {
      dispatch(fetchUserData());
    }
  };
};

export const unlockUser = (email) => {
  return async (dispatch, getState) => {
    dispatch(USERS_UNLOCK_USER_INIT());

    const data = {
      email,
    };
    let response;

    try {
      response = await axios.post(`${url}/auth/unlock`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      log(response);
    } catch (error) {
      let errorMessage;

      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);

      return dispatch(
        USERS_UNLOCK_USER_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    toastr.success('', `User has been unlocked`);

    return dispatch(USERS_UNLOCK_USER_SUCCESS({ email }));
  };
};

export const fetchUserData = () => {
  return async (dispatch) => {
    dispatch(USERS_FETCH_USER_DATA_INIT());

    const { uid } = localStorage.getItem('accessToken');

    let user;

    try {
      user = await axios.post(
        `${url}/user/get`,
        {
          id: uid,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      dispatch(logout());
      return dispatch(USERS_FETCH_USER_DATA_FAIL({ error }));
    }

    if (!user) {
      return dispatch(logout());
    }

    return dispatch(
      USERS_FETCH_USER_DATA_SUCCESS({
        id: uid,
        ...user,
      })
    );
  };
};

export const usersCleanUp = () => (dispatch) => dispatch(USERS_CLEAN_UP());

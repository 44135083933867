import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg } from 'utils';
import { getCsrf } from 'components/Report/utils';

export const SITES_FETCH_DATA_INIT = createAction('SITES_FETCH_DATA_INIT');
export const SITES_FETCH_DATA_SUCCESS = createAction(
  'SITES_FETCH_DATA_SUCCESS'
);
export const SITES_FETCH_DATA_FAIL = createAction('SITES_FETCH_DATA_FAIL');

export const SITES_DELETE_SITE_INIT = createAction('SITES_DELETE_SITE_INIT');
export const SITES_DELETE_SITE_SUCCESS = createAction(
  'SITES_DELETE_SITE_SUCCESS'
);
export const SITES_DELETE_SITE_FAIL = createAction('SITES_DELETE_SITE_FAIL');

export const SITES_CLEAR_DATA = createAction('SITES_CLEAR_DATA');

export const SITES_CREATE_SITE_INIT = createAction('SITES_CREATE_SITE_INIT');
export const SITES_CREATE_SITE_SUCCESS = createAction(
  'SITES_CREATE_SITE_SUCCESS'
);
export const SITES_CREATE_SITE_FAIL = createAction('SITES_CREATE_SITE_FAIL');

export const SITES_MODIFY_SITE_INIT = createAction('SITES_MODIFY_SITE_INIT');
export const SITES_MODIFY_SITE_SUCCESS = createAction(
  'SITES_MODIFY_SITE_SUCCESS'
);
export const SITES_MODIFY_SITE_FAIL = createAction('SITES_MODIFY_SITE_FAIL');

export const SITES_CLEAN_UP = createAction('SITES_CLEAN_UP');

export const SITES_CLEAR_DATA_LOGOUT = createAction('SITES_CLEAR_DATA_LOGOUT');

export const fetchSites = (siteId = '') => {
  return async (dispatch, getState) => {
    dispatch(SITES_FETCH_DATA_INIT());
    if (siteId) {
      let siteData;
      try {
        const res = await axios.post(
          `${url}/site/get`,
          {
            id: siteId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
        siteData = res.data.data;
      } catch (error) {
        log('fetch site error', error.message);
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(SITES_FETCH_DATA_FAIL({ error }));
      }

      const site = { ...siteData, id: siteId };
      log(site);
      log(siteData);
      return dispatch(
        SITES_FETCH_DATA_SUCCESS({
          list: getState().sites.list,
          site: site,
        })
      );
    }

    const { uid } = localStorage.getItem('uid');

    let sites;

    try {
      const res = await axios.get(`${url}/site/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      sites = res.data.data;
      log(sites);
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(SITES_FETCH_DATA_FAIL({ error }));
    }

    const sitesData = sites
      ? Object.entries(sites).map(([key, value]) => ({
          id: key,
          ...value,
        }))
      : [];

    return dispatch(
      SITES_FETCH_DATA_SUCCESS({
        list: sitesData.filter((site) => site.id !== uid),
        site: getState().sites.site,
      })
    );
  };
};

export const deleteSite = (id) => {
  return async (dispatch) => {
    dispatch(SITES_DELETE_SITE_INIT());

    try {
      const csrfToken = await getCsrf();
      await axios.delete(`${url}/site/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SITES_DELETE_SITE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The site was deleted.');
    return dispatch(SITES_DELETE_SITE_SUCCESS({ id }));
  };
};

export const createSite = ({
  name,
  description,
  organizationId,
  organizationName,
  isReqApproval,
  modifiedAt,
  modifiedBy,
}) => {
  return async (dispatch) => {
    dispatch(SITES_CREATE_SITE_INIT());
    const site = {
      name,
      description,
      organizationId,
      organizationName,
      isReqApproval,
      modifiedAt,
      modifiedBy,
    };

    try {
      const csrfToken = await getCsrf();
      await axios.post(`${url}/site/create`, site, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SITES_CREATE_SITE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'site created successfully');
    return dispatch(SITES_CREATE_SITE_SUCCESS({ site: site }));
  };
};

export const modifySite = ({
  name,
  description,
  organizationId,
  isReqApproval,
  organizationName,
  id,
}) => {
  return async (dispatch) => {
    dispatch(SITES_MODIFY_SITE_INIT());

    const siteData = {
      name,
      description,
      organizationId,
      organizationName,
      isReqApproval,
    };

    try {
      const csrfToken = await getCsrf();
      await axios.post(`${url}/site/${id}`, siteData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        SITES_MODIFY_SITE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Site updated successfully');

    return dispatch(SITES_MODIFY_SITE_SUCCESS({ site: siteData, id }));
  };
};

export const sitesCleanUp = () => (dispatch) => dispatch(SITES_CLEAN_UP());

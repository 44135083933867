import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { getIsPasswordChanged, getIsPasswordExpired, getRole } from '../../utils/secrets';
import { ReactComponent as OrganizationIcon } from '../../assets/icon/3square.svg';
import { setBreadcrumb } from 'state/actions/breadcrumb';
import _ from 'underscore';
import PageHeader from 'design-components/PageHeader';
import { doOpenChildren } from 'state/actions/menu';
import DashboardHeader from 'design-components/DashboardHeader';
import CreatedInfo from 'elements/created-info';
import './Home.css';

function Home({ history }) {

  const { organizationsList, sites, userData, openChildren, } = useSelector(
    (state) => ({
      organizationsList: state.organizations.list,
      sites: state.sites.list,
      userData: state.auth.userData,
      openChildren: state.menu.openChildren,
    }),
    shallowEqual
  );
  const [activeOrg, setActiveOrg] = useState();
  const [search, setSearch] = useState('');
  const [isShowSites, setIsShowSites] = useState(false);
  const [role, setRole] = useState(null);
  const [isPasswordChanged, setIsPasswordChanged] = useState(null);
  const [isPasswordExpired, setIsPasswordExpired] = useState(null);

  const dispatch = useDispatch();


  useEffect(() => {
    fetchData();
  }, []);

 const fetchData = async () => {
    try {
      const roleData = await getRole();
      const passwordChanged = await getIsPasswordChanged();
      const passwordExpired = await getIsPasswordExpired();
      console.log('password changed', passwordChanged, passwordExpired, !passwordChanged || passwordExpired)
      setRole(roleData);
      setIsPasswordChanged(passwordChanged);
      setIsPasswordExpired(passwordExpired);
    } catch (error) {
      log('Error retrieving role:', error);
    }
  };

  useEffect(() => {
    dispatch(doOpenChildren([]));
    dispatch(setBreadcrumb([{ menu: 'General Overview', link: '' }]));
    if (role && role !== 'superadmin') {
      const userAccess = userData.roleAccess
        ? userData.roleAccess.access
        : userData.access;
      const active = activeOrg ?? [];
      sites.map((site) => {
        const dataSite = userAccess.find((acc) => acc.siteId === site._id);
        if (dataSite) {
          if (dataSite.media || dataSite.poi || dataSite.information) {
            !active.includes(site.organizationId) &&
              active.push(site.organizationId);
            return null;
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
      setActiveOrg(active);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  console.log('user data', userData)
  const resetPassword = (!isPasswordChanged ||
   isPasswordExpired) && <Redirect to={paths.RESET_PASSWORD} />;
  const redirect = !role && <Redirect to={paths.ROOT} />;

  const handleOpenChildren = (id) => {
    setIsShowSites(true)
    const newChildren = [...openChildren];
    const findChildren = newChildren
      .find((child) => child._id === id);
    if (findChildren) {
      findChildren.open = !findChildren.open;
      dispatch(doOpenChildren(newChildren));
    } else {
      dispatch(
        doOpenChildren([
          ...openChildren,
          {
            _id: id,
            open: true,
          },
        ])
      );
    }
  };

  const findChildrenOpen = (id) => {
    const findChildren = openChildren.find((x) => x._id === id);
    if (findChildren) {
      return findChildren.open;
    }
    return false;
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="dashboard">
      {isPasswordChanged && isPasswordExpired && resetPassword}
      {role && redirect}
      <div>
        <PageHeader
          title="General Overview"
          isGrid={false}
          isSearch={true}
          searchValue={search}
          searchAction={handleSearch}
          searchPlaceholder="Enter Keywords ..."
          isAdd={false}
        />
      </div>

      {role && role === 'superadmin'
        ? // eslint-disable-next-line
        _.sortBy(organizationsList, (i) => i.name.toLowerCase())
          .filter(org => org.name.toLowerCase().includes(search))
          .map((organization) => {
            return (
              <div className="dashboard-table" key={organization._id} >
                <div className="card-data-header">
                  <div className="card-info">
                    <CreatedInfo user={organization.modifiedBy} limit={100} />
                  </div>
                </div>
                <DashboardHeader
                  id={organization._id}
                  title={organization.name}
                  isActive={isShowSites && findChildrenOpen(organization._id)}
                  clickAction={() => handleOpenChildren(organization._id)}
                />
                <div className={`dashboard-row-wrapper ${isShowSites && findChildrenOpen(organization._id) ? 'active' : ''}`}>
                  {sites &&
                    isShowSites && findChildrenOpen(organization._id) && _.sortBy(sites, (i) => i.name.toLowerCase())
                      .filter(
                        (site) => site.organizationId === organization._id
                      )
                      .map((site) => (
                        <div className="dashboard-row" key={site._id}>
                          <Link to={`/${site._id}/information/${site.name}`}>
                            <OrganizationIcon />
                            <span className="dashboard-span">{site.name.length > 30 ? `${site.name.substring(0, 30)}...` : site.name}</span>
                            <i className="feather-chevron-right" />
                          </Link>
                        </div>
                      ))}
                </div>
              </div>
            );
          })
        : // eslint-disable-next-line
        _.sortBy(organizationsList, (i) => i.name.toLowerCase()).map((organization) => {
          const siteOrg = sites.filter(
            (site) => site.organizationId === organization._id
          );
          if (activeOrg) {
            if (activeOrg.includes(organization._id) && activeOrg.filter(org => org.toLowerCase().includes(search))) {
              return (
                <div className="dashboard-table" key={organization._id}>
                  <div className="card-data-header">
                    <div className="card-info">
                      <CreatedInfo user={organization.modifiedBy} limit={100} />
                    </div>
                  </div>
                  <DashboardHeader
                    id={organization._id}
                    title={organization.name}
                    isActive={findChildrenOpen(organization._id)}
                    clickAction={() => handleOpenChildren(organization._id)}
                  />
                  <div className={`dashboard-row-wrapper `}>
                    {findChildrenOpen(organization._id) && _.sortBy(siteOrg, (i) => i.name.toLowerCase()).map(
                      (site) => {
                        const access = userData.roleAccess
                          ? userData.roleAccess.access.find(
                            (acc) => acc.siteId === site._id
                          )
                          : userData.access.find(
                            (acc) => acc.siteId === site._id
                          );

                        if (
                          access &&
                          (access.media ||
                            access.poi ||
                            access.information ||
                            access.screen)
                        ) {
                          return (
                            <div className="dashboard-row" key={site._id}>
                              <Link to={`/${site._id}/information/${site.name}`}>
                                <OrganizationIcon />
                                <span className="dashboard-span">
                                  {site.name.length > 30 ? `${site.name.substring(0, 30)}...` : site.name}
                                </span>
                              </Link>
                            </div>
                          );
                        } else {
                          return <></>;
                        }
                      }
                    )}
                  </div>
                </div>
              )

            } else {
              return <></>;
            }
          } else {
            return <></>;
          }
        })}
    </div>
  );
}

export default Home;

import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg } from 'utils';
import { getCsrf } from 'components/Report/utils';

export const INFORMATION_CATEGORIES_FETCH_DATA_INIT = createAction(
  'INFORMATION_CATEGORIES_FETCH_DATA_INIT'
);
export const INFORMATION_CATEGORIES_FETCH_DATA_SUCCESS = createAction(
  'INFORMATION_CATEGORIES_FETCH_DATA_SUCCESS'
);
export const INFORMATION_CATEGORIES_FETCH_DATA_FAIL = createAction(
  'INFORMATION_CATEGORIES_FETCH_DATA_FAIL'
);

export const INFORMATION_CATEGORIES_DELETE_CATEGORY_INIT = createAction(
  'INFORMATION_CATEGORIES_DELETE_CATEGORY_INIT'
);
export const INFORMATION_CATEGORIES_DELETE_CATEGORY_SUCCESS = createAction(
  'INFORMATION_CATEGORIES_DELETE_CATEGORY_SUCCESS'
);
export const INFORMATION_CATEGORIES_DELETE_CATEGORY_FAIL = createAction(
  'INFORMATION_CATEGORIES_DELETE_CATEGORY_FAIL'
);

export const INFORMATION_CATEGORIES_CLEAR_DATA = createAction(
  'INFORMATION_CATEGORIES_CLEAR_DATA'
);

export const INFORMATION_CATEGORIES_CREATE_CATEGORY_INIT = createAction(
  'INFORMATION_CATEGORIES_CREATE_CATEGORY_INIT'
);
export const INFORMATION_CATEGORIES_CREATE_CATEGORY_SUCCESS = createAction(
  'INFORMATION_CATEGORIES_CREATE_CATEGORY_SUCCESS'
);
export const INFORMATION_CATEGORIES_CREATE_CATEGORY_FAIL = createAction(
  'INFORMATION_CATEGORIES_CREATE_CATEGORY_FAIL'
);

export const INFORMATION_CATEGORIES_MODIFY_CATEGORY_INIT = createAction(
  'INFORMATION_CATEGORIES_MODIFY_CATEGORY_INIT'
);
export const INFORMATION_CATEGORIES_MODIFY_CATEGORY_SUCCESS = createAction(
  'INFORMATION_CATEGORIES_MODIFY_CATEGORY_SUCCESS'
);
export const INFORMATION_CATEGORIES_MODIFY_CATEGORY_FAIL = createAction(
  'INFORMATION_CATEGORIES_MODIFY_CATEGORY_FAIL'
);

export const INFORMATION_CATEGORIES_CLEAN_UP = createAction(
  'INFORMATION_CATEGORIES_CLEAN_UP'
);

export const INFORMATION_CATEGORIES_CLEAR_DATA_LOGOUT = createAction(
  'INFORMATION_CATEGORIES_CLEAR_DATA_LOGOUT'
);

export const SET_INFORMATION_CATEGORY = createAction(
  'SET_INFORMATION_CATEGORY'
);

export const fetchInformationCategories = ({
  informationCategoryId,
  siteId,
}) => {
  return async (dispatch, getState) => {
    dispatch(INFORMATION_CATEGORIES_FETCH_DATA_INIT());
    if (informationCategoryId) {
      let informationCategoryData;
      try {
        informationCategoryData = await Axios.get(
          `${url}/informationCategory/list`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(INFORMATION_CATEGORIES_FETCH_DATA_FAIL({ error }));
      }
      const informationCategory = {
        ...informationCategoryData,
        id: informationCategoryId,
      };
      return dispatch(
        INFORMATION_CATEGORIES_FETCH_DATA_SUCCESS({
          list: getState().categories.list,
          informationCategory,
        })
      );
    }

    if (siteId) {
      let informationCategories;
      try {
        informationCategories = await Axios.get(
          `${url}/informationCategory/list`,
          {
            params: {
              siteId,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
        log(informationCategories, '<<< infor cat');
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(INFORMATION_CATEGORIES_FETCH_DATA_FAIL({ error }));
      }

      informationCategories = informationCategories.data.data.filter(
        (informationCategory) => informationCategory.siteId === siteId
      );

      return dispatch(
        INFORMATION_CATEGORIES_FETCH_DATA_SUCCESS({
          list: informationCategories,
          informationCategory:
            getState().informationCategories.informationCategory,
        })
      );
    }
  };
};

export const deleteInformationCategory = (id, cb) => {
  return async (dispatch, getState) => {
    dispatch(INFORMATION_CATEGORIES_DELETE_CATEGORY_INIT());
    try {
      const csrfToken = await getCsrf();
      await Axios.delete(`${url}/informationCategory/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        INFORMATION_CATEGORIES_DELETE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }
    cb();
    toastr.success('', 'The information category was deleted.');
    return dispatch(INFORMATION_CATEGORIES_DELETE_CATEGORY_SUCCESS({ id }));
  };
};

export const clearInformationCategoriesData = () => {
  return (dispatch) => {
    dispatch(INFORMATION_CATEGORIES_CLEAR_DATA());
  };
};

export const clearInformationCategoriesDataLogout = () => {
  return (dispatch) => {
    dispatch(INFORMATION_CATEGORIES_CLEAR_DATA_LOGOUT());
  };
};

export const createInformationCategory = (
  { uid, name, description, siteId, modifiedAt, modifiedBy },
  cb
) => {
  return async (dispatch, getState) => {
    dispatch(INFORMATION_CATEGORIES_CREATE_CATEGORY_INIT());
    let informationCategory;
    try {
      const csrfToken = await getCsrf();
      informationCategory = await Axios.post(
        `${url}/informationCategory/create`,
        {
          name,
          description,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'X-CSRF-TOKEN': csrfToken,
          },
          withCredentials: true,
        }
      );
    } catch (error) {
      log(error.response, '<<< error response');
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        INFORMATION_CATEGORIES_CREATE_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    toastr.success('', 'Information category created successfully');
    cb();
    return dispatch(
      INFORMATION_CATEGORIES_CREATE_CATEGORY_SUCCESS({
        informationCategory: informationCategory.data.data,
      })
    );
  };
};

export const modifyInformationCategory = (
  { id, name, description, siteId, modifiedAt, modifiedBy },
  cb
) => {
  return async (dispatch, getState) => {
    dispatch(INFORMATION_CATEGORIES_MODIFY_CATEGORY_INIT());

    const informationCategoryData = {
      id,
      name,
      description,
      siteId,
      modifiedAt,
      modifiedBy,
    };

    const csrfToken = await getCsrf();
    const updateInformationCategoryDbTask = await Axios.post(
      `${url}/informationCategory/edit`,
      informationCategoryData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'X-CSRF-TOKEN': csrfToken,
        },
        withCredentials: true,
      }
    );

    try {
      await Promise.all([updateInformationCategoryDbTask]);
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      cb();
      return dispatch(
        INFORMATION_CATEGORIES_MODIFY_CATEGORY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Information category updated successfully');
    cb();
    return dispatch(
      INFORMATION_CATEGORIES_MODIFY_CATEGORY_SUCCESS({
        informationCategory: updateInformationCategoryDbTask.data.data,
        id,
      })
    );
  };
};

export const informationCategoriesCleanUp = () => (dispatch) =>
  dispatch(INFORMATION_CATEGORIES_CLEAN_UP());

export const setInformationCategory = (data, cb) => {
  return (dispatch) => {
    dispatch(SET_INFORMATION_CATEGORY({ data }));
    if (cb) {
      cb();
    }
  };
};

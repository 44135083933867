import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg  } from 'utils';

export const FILES_FETCH_DATA_INIT = createAction('FILES_FETCH_DATA_INIT');
export const FILES_FETCH_DATA_SUCCESS = createAction(
  'FILES_FETCH_DATA_SUCCESS'
);
export const FILES_FETCH_DATA_FAIL = createAction('FILES_FETCH_DATA_FAIL');

export const FILES_DELETE_FILE_INIT = createAction('FILES_DELETE_FILE_INIT');
export const FILES_DELETE_FILE_SUCCESS = createAction(
  'FILES_DELETE_FILE_SUCCESS'
);
export const FILES_DELETE_FILE_FAIL = createAction('FILES_DELETE_FILE_FAIL');

export const FILES_CLEAR_DATA = createAction('FILES_CLEAR_DATA');

export const FILES_CREATE_FILE_INIT = createAction('FILES_CREATE_FILE_INIT');
export const FILES_CREATE_FILE_SUCCESS = createAction(
  'FILES_CREATE_FILE_SUCCESS'
);
export const FILES_CREATE_FILE_FAIL = createAction('FILES_CREATE_FILE_FAIL');

export const FILES_MODIFY_FILE_INIT = createAction('FILES_MODIFY_FILE_INIT');
export const FILES_MODIFY_FILE_SUCCESS = createAction(
  'FILES_MODIFY_FILE_SUCCESS'
);
export const FILES_MODIFY_FILE_FAIL = createAction('FILES_MODIFY_FILE_FAIL');

export const FILES_CLEAN_UP = createAction('FILES_CLEAN_UP');

export const FILES_CLEAR_DATA_LOGOUT = createAction('FILES_CLEAR_DATA_LOGOUT');

export const fetchFiles = ({ id }) => {
  return async (dispatch, getState) => {
    dispatch(FILES_FETCH_DATA_INIT());
    if (id) {
      let fileData;

      try {
        const res = await Axios.get(`${url}/files/get`, {
          params: {
            id,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        fileData = res.data.data;
      } catch (error) {
        log('fetch file error', error.message);
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(FILES_FETCH_DATA_FAIL({ error }));
      }

      const file = { ...fileData, id: id };
      log(file);
      log(fileData);
      return dispatch(
        FILES_FETCH_DATA_SUCCESS({
          list: fileData,
          file: file,
        })
      );
    }
  };
};

export const deleteFile = (id, cb) => {
  return async (dispatch, getState) => {
    dispatch(FILES_DELETE_FILE_INIT());

    try {
      await Axios.delete(`${url}/files/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        FILES_DELETE_FILE_FAIL({
          error: errorMessage,
        })
      );
    }
    cb();
    toastr.success('', 'The FILE was deleted.');
    return dispatch(FILES_DELETE_FILE_SUCCESS({ id }));
  };
};

export const createFile = ({ screenId, ipk }) => {
  return async (dispatch, getState) => {
    dispatch(FILES_CREATE_FILE_INIT());
    let fileData;
    let file = new FormData();
    file.set('screenId', screenId);
    file.append('fileIPK', ipk.file);

    try {
      fileData = await Axios.post(`${url}/files/upload`, file, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        FILES_CREATE_FILE_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    toastr.success('', 'file uploaded successfully');
    return dispatch(FILES_CREATE_FILE_SUCCESS({ file: fileData.data.data }));
  };
};

// export const modifyFile = ({
//   name,
//   description,
//   organizationId,
//   organizationName,
//   modifiedAt,
//   modifiedBy,
//   id,
// }) => {
//   return async (dispatch, getState) => {
//     dispatch(FILES_MODIFY_FILE_INIT());

//     const FILEData = {
//       name,
//       description,
//       organizationId,
//       organizationName,
//     };

//     try {
//       await axios.post(`${url}/FILE/${id}`, siteData, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
//         },
//       });
//     } catch (error) {
//       log(error);
//       let errorMessage;
//       if (error.response) {
//         errorMessage = error && error.response && error.response.data.message;
//       }
//       toastr.error('', errorMessage);
//       return dispatch(
//         FILES_MODIFY_SITE_FAIL({
//           error: error && error.response && error.response.data,
//         })
//       );
//     }

//     toastr.success('', 'Site updated successfully');

//     return dispatch(FILES_MODIFY_SITE_SUCCESS({ site: siteData, id }));
//   };
// };

export const filesCleanUp = () => (dispatch) => dispatch(FILES_CLEAN_UP());

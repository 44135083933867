import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Login from 'pages/Login';
import Home from 'pages/Home';
import SiteInfo from 'pages/SiteInfo';
import Users from 'pages/Users';
import User from 'pages/User';
import UserManagementSys from 'pages/UserManagementSys';
import Organizations from 'pages/Organizations';
import Organization from 'pages/Organization';
import Sites from 'pages/Sites';
import Screens from 'pages/Screens';
import Site from 'pages/Site';
import Pois from 'pages/Pois';
import Poi from 'pages/Poi';
import Categories from 'pages/Categories';
import MediaCategories from 'pages/MediaCategories';
import Category from 'pages/Category';
import MediaCategory from 'pages/MediaCategory';
import MediaCalendar from 'pages/MediaCalendar';
import Profile from 'pages/Profile';
import ChangePassword from 'pages/ChangePassword';
import NotFound from 'pages/NotFound';
import Section from 'pages/Section';
import Submenu from 'pages/Submenu';
import PrivateRoute from './PrivateRoute';
import MediaLibrary from 'pages/MediaLibrary';
import Media from 'pages/Media';
import Analytics from 'pages/Analytics';
import Paths from 'pages/Paths';
import AllCalendar from 'pages/AllCalendar';
// import Feedback from 'pages/FeedBack';
// import Respondent from 'pages/Respodent';
import Screenshot from 'pages/Screenshot';
import FullLayout from 'design-components/Layout/FullLayout';
import ResetPassword from 'pages/ResetPassword';
import RecoverPassword from 'pages/RecoverPassword';
import Information from 'pages/Information';
import InformationCategories from 'pages/InformationCategories';
import Bugs from 'pages/Bugs';
import Logs from 'pages/Logs';
import AuthLogs from 'pages/AuthLogs';
import Roles from 'pages/Roles';
import Role from 'pages/Role';
import Panel from 'pages/Panel';
import PanelRole from 'pages/PanelRole';
import PanelUser from 'pages/PanelUser';
import ScreenUser from 'pages/ScreenUser';
import ScreenUsers from 'pages/ScreenUsers';
import Informations from 'pages/Informations';
import ScreenInfo from 'pages/ScreenInfo';
import MFA from 'pages/MFA';
import MFAManagement from 'pages/MFAManagement';
import Programme from 'pages/Programme';
import Programmes from 'pages/Programmes';
import paths from './paths';


const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={paths.LOGIN}
          component={(props) => {
            return (
              <FullLayout {...props}>
                <Login {...props} />
              </FullLayout>
            );
          }}
        />
        <Route
          exact
          path={paths.MFA}
          component={(props) => {
            return (
              <FullLayout {...props}>
                <MFA {...props} />
              </FullLayout>
            );
          }}
        />
        <Route
          exact
          path={paths.RECOVER_PASSWORD}
          component={(props) => {
            return (
              <FullLayout {...props}>
                <RecoverPassword {...props} />
              </FullLayout>
            );
          }}
        />
        <Route
          exact
          path={paths.RESET_PASSWORD}
          component={(props) => {
            return (
              <FullLayout {...props}>
                <ResetPassword />
              </FullLayout>
            );
          }}
        ></Route>
        <PrivateRoute path={paths.ADD_USER} component={User} />
        <PrivateRoute path={paths.MANAGE_USER} component={UserManagementSys} />
        <PrivateRoute path={paths.MODIFY_USER} component={User} />

        <PrivateRoute path={paths.USERS} component={Users} />
        <PrivateRoute path={paths.MFAManagement} component={MFAManagement} />
        <PrivateRoute
          path={paths.USER_MANAGEMENT_SYS}
          component={UserManagementSys}
        />

        <PrivateRoute path={paths.ADD_SITE} component={Site} />
        <PrivateRoute path={paths.MODIFY_SITE} component={Site} />
        <PrivateRoute path={paths.SITES} component={Sites} />
        <PrivateRoute path={paths.ADD_ORGANIZATION} component={Organization} />
        <PrivateRoute
          path={paths.MODIFY_ORGANIZATION}
          component={Organization}
        />
        <PrivateRoute path={paths.ORGANIZATIONS} component={Organizations} />
        <PrivateRoute path={paths.ADD_POI} component={Poi} />
        <PrivateRoute path={paths.MODIFY_POI} component={Poi} />
        <PrivateRoute path={paths.POIS} component={Pois} />
        <PrivateRoute path={paths.ADD_CATEGORY} component={Category} />
        <PrivateRoute path={paths.MODIFY_CATEGORY} component={Category} />
        <PrivateRoute path={paths.CATEGORIES} component={Categories} />
        <PrivateRoute
          path={paths.ADD_MEDIA_CATEGORY}
          component={MediaCategory}
        />
        <PrivateRoute
          path={paths.MODIFY_MEDIA_CATEGORY}
          component={MediaCategory}
        />
        <PrivateRoute
          path={paths.MEDIACATEGORIES}
          component={MediaCategories}
        />
        <PrivateRoute path={paths.CALENDAR} component={AllCalendar} />
        <PrivateRoute path={paths.MEDIA_CALENDAR} component={MediaCalendar} />
        <PrivateRoute path={paths.ADD_MEDIA} component={Media} />
        <PrivateRoute path={paths.MODIFY_MEDIA} component={Media} />
        <PrivateRoute path={paths.MEDIA} component={MediaLibrary} />
        {/* <PrivateRoute path={paths.ADD_FEEDBACK} component={Feedback} />
        <PrivateRoute path={paths.MODIFY_FEEDBACK} component={Feedback} />
        <PrivateRoute path={paths.RESPONDENT_FEEDBACK} component={Respondent} /> */}
        <PrivateRoute path={paths.SCREENSHOTS} component={Screenshot} />
        <PrivateRoute path={paths.ADD_INFORMATION} component={Informations} />
        <PrivateRoute
          path={paths.MODIFY_INFORMATION}
          component={Informations}
        />
        <PrivateRoute path={paths.INFORMATION} component={Information} />
        <PrivateRoute path={paths.ADD_PROGRAMME} component={Programme} />
        <PrivateRoute path={paths.MODIFY_PROGRAMME} component={Programme} />
        <PrivateRoute path={paths.PROGRAMME} component={Programmes} />
        <PrivateRoute
          path={paths.INFORMATION_CATEGORIES}
          component={InformationCategories}
        />
        <PrivateRoute path={paths.SCREENS} component={Screens} />
        <PrivateRoute path={paths.ANALYTICS} component={Analytics} />
        <PrivateRoute path={paths.PATHS} component={Paths} />
        <PrivateRoute path={paths.PROFILE} component={Profile} />
        <PrivateRoute path={paths.CHANGE_PASSWORD} component={ChangePassword} />
        <PrivateRoute path={paths.SECTION} component={Section} />
        <PrivateRoute path={paths.SUBMENU_1} component={Submenu} />
        <PrivateRoute path={paths.SUBMENU_3} component={Submenu} />
        <PrivateRoute path={paths.SITEINFO} component={SiteInfo} />
        <PrivateRoute path={paths.BUGS} component={Bugs} />
        <PrivateRoute path={paths.LOGS} component={Logs} />
        <PrivateRoute path={paths.AUTH_LOGS} component={AuthLogs} />
        <PrivateRoute path={paths.ADD_ROLES} component={Role} />
        <PrivateRoute path={paths.MODIFY_ROLES} component={Role} />
        <PrivateRoute path={paths.ROLES} component={Roles} />
        <PrivateRoute path={paths.PANEL_ROLE} component={PanelRole} />
        <PrivateRoute path={paths.PANEL} component={Panel} />
        <PrivateRoute path={paths.PANEL_USER} component={PanelUser} />
        <PrivateRoute path={paths.SCREEN_USER} component={ScreenUser} />
        <PrivateRoute path={paths.MODIFY_SCREEN_USER} component={ScreenUser} />
        <PrivateRoute path={paths.SCREEN_USERS} component={ScreenUsers} />
        <PrivateRoute path={paths.SCREENINFO} component={ScreenInfo} />
        <PrivateRoute path={paths.ROOT} component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;

import { useState, useEffect } from 'react';
import axios from 'axios';
import { log } from 'utils';
import { url } from 'utils/url';
import { logout } from 'state/actions/auth';
// import { useDispatch } from 'react-redux';

export const getRole = async () => {
  try {
    const response = await axios.get(`${url}/user/role`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response.data.data;
  } catch (error) {
    log('Error retrieving role:', error);
    logout();
    return null;
  }
};

export const getIsPasswordChanged = async () => {
  try {
    const response = await axios.get(`${url}/user/isPasswordChanged`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response.data.data;
  } catch (error) {
    log('Error retrieving role:', error);
    logout();
    return false;
  }
};

export const getIsPasswordExpired = async () => {
  try {
    const response = await axios.get(`${url}/user/isPasswordExpired`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response.data.data;
  } catch (error) {
    log('Error retrieving role:', error);
    logout();
    return false;
  }
};

export const getRoleDispatch = async (dispatch) => {

  try {
    const response = await axios.get(`${url}/user/role`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response.data.data;
  } catch (error) {
    log('Error retrieving role:', error);
    if (localStorage.getItem('accessToken')) dispatch(logout());
    return null;
  }
};

export const getRoleHooks = (accessToken) => {
  const [role, setRole] = useState(null);
  const [isUserRoleChecked, setIsUserRoleChecked] = useState(false);
  // const dispatch = useDispatch();

  const checkUserRole = async () => {
    log(`check userrole token ? ${accessToken}`);
    try {
      const response = await axios.get(`${url}/user/role`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      setIsUserRoleChecked(true);
      setRole(response.data.data);
    } catch (error) {
      log('Error retrieving role:', error);
      setIsUserRoleChecked(true);
      // dispatch(logout());
      return null;
    }
  };

  useEffect(() => {
    if (!isUserRoleChecked) {
      checkUserRole();
    }
  }, [isUserRoleChecked]);

  return [role, checkUserRole];
};

import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg  } from 'utils';

export const FLOOR_FETCH_DATA_INIT = createAction('FLOOR_FETCH_DATA_INIT');
export const FLOOR_FETCH_DATA_SUCCESS = createAction(
  'FLOOR_FETCH_DATA_SUCCESS'
);
export const FLOOR_FETCH_DATA_FAIL = createAction('FLOOR_FETCH_DATA_FAIL');

export const FLOOR_DELETE_FLOOR_INIT = createAction('FLOOR_DELETE_FLOOR_INIT');
export const FLOOR_DELETE_FLOOR_SUCCESS = createAction(
  'FLOOR_DELETE_FLOOR_SUCCESS'
);
export const FLOOR_DELETE_FLOOR_FAIL = createAction('FLOOR_DELETE_FLOOR_FAIL');

export const FLOOR_CLEAR_DATA = createAction('FLOOR_CLEAR_DATA');

export const FLOOR_CREATE_FLOOR_INIT = createAction('FLOOR_CREATE_FLOOR_INIT');
export const FLOOR_CREATE_FLOOR_SUCCESS = createAction(
  'FLOOR_CREATE_FLOOR_SUCCESS'
);
export const FLOOR_CREATE_FLOOR_FAIL = createAction('FLOOR_CREATE_FLOOR_FAIL');

export const FLOOR_MODIFY_FLOOR_INIT = createAction('FLOOR_MODIFY_FLOOR_INIT');
export const FLOOR_MODIFY_FLOOR_SUCCESS = createAction(
  'FLOOR_MODIFY_FLOOR_SUCCESS'
);
export const FLOOR_MODIFY_FLOOR_FAIL = createAction('FLOOR_MODIFY_FLOOR_FAIL');

export const FLOOR_CLEAN_UP = createAction('FLOOR_CLEAN_UP');

export const FLOOR_CLEAR_DATA_LOGOUT = createAction('FLOOR_CLEAR_DATA_LOGOUT');
export const SET_FLOOR = createAction('SET_FLOOR');

export const fetchFloor = ({ floorId, siteId }) => {
  return async (dispatch, getState) => {
    dispatch(FLOOR_FETCH_DATA_INIT());
    if (floorId) {
      let floorData;
      try {
        floorData = await Axios.get(`${url}/floor/get`, {
          params: {
            id: floorId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(FLOOR_FETCH_DATA_FAIL({ error }));
      }

      const floor = { ...floorData.data.data, id: floorId };
      return dispatch(
        FLOOR_FETCH_DATA_SUCCESS({
          list: getState().floor.list,
          floor,
        })
      );
    }

    if (siteId) {
      let floor;
      try {
        floor = await Axios.get(`${url}/floor/list`, {
          params: {
            siteId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(FLOOR_FETCH_DATA_FAIL({ error }));
      }
      return dispatch(
        FLOOR_FETCH_DATA_SUCCESS({
          list: floor.data.data,
          floor: getState().floor.floor,
        })
      );
    }
  };
};

export const deleteFloor = (id, cb) => {
  return async (dispatch, getState) => {
    dispatch(FLOOR_DELETE_FLOOR_INIT());

    try {
      await Axios.delete(`${url}/floor/delete`, {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      log(errorMessage);
      return dispatch(
        FLOOR_DELETE_FLOOR_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The floor was deleted.');
    cb();
    return dispatch(FLOOR_DELETE_FLOOR_SUCCESS({ id }));
  };
};

export const clearFloorData = () => {
  return (dispatch) => {
    dispatch(FLOOR_CLEAR_DATA());
  };
};

export const clearFloorDataLogout = () => {
  return (dispatch) => {
    dispatch(FLOOR_CLEAR_DATA_LOGOUT());
  };
};

export const createFloor = ({ name, description, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(FLOOR_CREATE_FLOOR_INIT());

    let createFloor;
    try {
      createFloor = await Axios.post(
        `${url}/floor/create`,
        {
          name,
          description,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        FLOOR_CREATE_FLOOR_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Floor created successfully');
    cb();

    dispatch(
      SET_FLOOR({
        data: {
          name: '',
          description: '',
        },
      })
    );

    return dispatch(
      FLOOR_CREATE_FLOOR_SUCCESS({ floor: createFloor.data.data })
    );
  };
};

export const modifyFloor = ({ id, name, description, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(FLOOR_MODIFY_FLOOR_INIT());
    let updateFloor;
    try {
      updateFloor = await Axios.post(
        `${url}/floor/edit`,
        {
          name,
          description,
          siteId,
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      log(error);
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        FLOOR_MODIFY_FLOOR_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Floor updated successfully');
    cb();

    dispatch(
      SET_FLOOR({
        data: {
          name: '',
          description: '',
        },
      })
    );

    return dispatch(
      FLOOR_MODIFY_FLOOR_SUCCESS({
        floor: updateFloor.data.data,
        id,
      })
    );
  };
};

export const floorCleanUp = () => (dispatch) => dispatch(FLOOR_CLEAN_UP());
export const setFloor = (data, cb) => {
  return (dispatch) => {
    dispatch(SET_FLOOR({ data }));
    if (cb) {
      cb();
    }
  };
};

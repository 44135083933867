import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { log, errorMsg } from 'utils';

export const SETTINGS_FETCH_DATA_INIT = createAction(
  'SETTINGS_FETCH_DATA_INIT'
);
export const SETTINGS_FETCH_DATA_SUCCESS = createAction(
  'SETTINGS_FETCH_DATA_SUCCESS'
);
export const SETTINGS_FETCH_DATA_FAIL = createAction(
  'SETTINGS_FETCH_DATA_FAIL'
);

export const SETTINGS_CLEAR_DATA = createAction('SETTINGS_CLEAR_DATA');

export const SETTINGS_MODIFY_INIT = createAction('SETTINGS_MODIFY_INIT');
export const SETTINGS_MODIFY_SUCCESS = createAction('SETTINGS_MODIFY_SUCCESS');
export const SETTINGS_MODIFY_FAIL = createAction('SETTINGS_MODIFY_FAIL');

export const SETTINGS_CLEAN_UP = createAction('SETTINGS_CLEAN_UP');

export const SETTINGS_CLEAR_DATA_LOGOUT = createAction(
  'SETTINGS_CLEAR_DATA_LOGOUT'
);

export const fetchSettings = ({ siteId, screenId }) => {
  return async (dispatch) => {
    dispatch(SETTINGS_FETCH_DATA_INIT());
    if (siteId) {
      let settings;
      try {
        settings = await Axios.get(`${url}/settings/list`, {
          params: {
            siteId,
            screenId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        });
        log(settings, '<<< infor cat');
      } catch (error) {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(SETTINGS_FETCH_DATA_FAIL({ error }));
      }

      settings = settings.data.data;

      return dispatch(
        SETTINGS_FETCH_DATA_SUCCESS({
          settings,
        })
      );
    }
  };
};

export const connectSocket = async (screenId) => {
  if (screenId) {
    try {
      const res = await Axios.post(
        `${url}/socket/connect`,
        {
          screenId: screenId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      log(res);
    } catch (error) {
      log(error);
      toastr.error('', `${error}`);
    }
  }
};

export const changeVolume = async (screenId, value) => {
  if (screenId) {
    try {
      const res = await Axios.post(
        `${url}/socket/volume`,
        {
          screenId: screenId,
          value: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      log(res);
    } catch (error) {
      log(error);
      toastr.error('', `${error}`);
    }
  }
};

export const changeMute = async (screenId, value) => {
  if (screenId) {
    try {
      const res = await Axios.post(
        `${url}/socket/volume`,
        {
          screenId: screenId,
          value: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      log(res);
    } catch (error) {
      log(error);
      toastr.error('', `${error}`);
    }
  }
};

export const changeDisplay = async (screenId, data) => {
  if (screenId) {
    try {
      const res = await Axios.post(
        `${url}/socket/display`,
        {
          screenId: screenId,
          data: data,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      log(res);
    } catch (error) {
      log(error);
      toastr.error('', `${error}`);
    }
  }
};

export const screenShot = async (screenId) => {
  if (screenId) {
    try {
      const res = await Axios.post(
        `${url}/socket/screenshot`,
        {
          screenId: screenId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      log(res);
    } catch (error) {
      log(error);
      toastr.error('', `${error}`);
    }
  }
};

export const installApplication = async (screenId, downloadUrl) => {
  if (screenId) {
    try {
      const res = await Axios.post(
        `${url}/socket/install`,
        {
          screenId: screenId,
          url: downloadUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      log(res);
      toastr.success('', 'Application has been installed successfully');
    } catch (error) {
      log(error);
      toastr.error('', `${error}`);
    }
  }
};

export const restartScreen = async (screenId) => {
  if (screenId) {
    try {
      const res = await Axios.post(
        `${url}/socket/restart`,
        {
          screenId: screenId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      log(res);
      toastr.success('', 'Screen has restarted successfully');
    } catch (error) {
      log(error);
      toastr.error('', `${error}`);
    }
  }
};

export const forceUpdate = async (screenId) => {
  if (screenId) {
    try {
      const res = await Axios.post(
        `${url}/socket/update`,
        {
          screenId: screenId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      log(res);
      toastr.success('', 'Screen has updated successfully');
    } catch (error) {
      log(error);
      toastr.error('', `${error}`);
    }
  }
};

export const clearSettingsData = () => {
  return (dispatch) => {
    dispatch(SETTINGS_CLEAR_DATA());
  };
};

export const clearSettingsDataLogout = () => {
  return (dispatch) => {
    dispatch(SETTINGS_CLEAR_DATA_LOGOUT());
  };
};

export const modifysettings = ({
  siteId,
  screenId,
  settings,
  modifiedAt,
  modifiedBy,
}) => {
  return async (dispatch) => {
    dispatch(SETTINGS_MODIFY_INIT());

    const settingsData = {
      siteId,
      screenId,
      settings,
      modifiedAt,
      modifiedBy,
    };

    const updatesettingsDbTask = Axios.post(
      `${url}/settings/edit`,
      settingsData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
    );

    try {
      await Promise.all([updatesettingsDbTask]);
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);

      return dispatch(
        SETTINGS_MODIFY_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'Settings updated successfully');

    return dispatch(
      SETTINGS_MODIFY_SUCCESS({
        settings: settingsData,
      })
    );
  };
};

export const settingsCleanUp = () => (dispatch) =>
  dispatch(SETTINGS_CLEAN_UP());
